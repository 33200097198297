/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
}
.filtre {
  padding-right: 100px;
}

.app-wrapper {
  //padding-right: 10px;
  //padding-left: 80px;
  padding: 20px;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding: 5px;
  }
}
